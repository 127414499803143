import React, { useState, useEffect } from 'react'
import UserCard from './SpecialistListItem/CardView/UserCard'
import {
    Grid,
    Zoom,
    Typography,
    CircularProgress,
    Button,
    Paper,
    TextField,
    Tooltip,
    LinearProgress
} from '@mui/material/';
import EnhancedTable from './SpecialistListItem/TableView/UserTable';
import { useSpecialists } from '../hooks/specialist';
import { useOfficeSpecialists } from "../hooks/officeSpecialists";
import {
    download,
    formatMultipleOptions,
    handleFilter,
    handleOfficeSpecialistFilter,
    removeLastAmpersand,
    TransitionLeft
} from '../helpers/utils';
import { useNavigate } from 'react-router-dom';
import SpecialistFilters from '../components/SpecialistFilters';
import { apiFetch } from '../service/apiFetch';
import {Waypoint} from "react-waypoint";
import ShareCvPopup from '../components/ShareCvPopup';
import { handleFormError } from '../service/security/formError';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CsvPopup from './SpecialistListItem/CsvPopup';
import ExportCvPopup from './SpecialistListItem/ExportCvPopup';
import ExportDocumentPopup from './SpecialistListItem/ExportDocumentPopup';
import OfficeSpecialistFilters from "../components/OfficeSpecialistFilters";
import OfficeSpecialistCard from "./SpecialistListItem/CardView/OfficeSpecialistCard";

let debounce = null

export default function SpecialistList({
    filterList,
    setFilterList,
    phoneResponsive,
    darkState,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    handleToggle,
    handleCloseBackdrop,
    language,
    setLanguage,
    languages,
    setLanguages,
    activity,
    setActivity,
    activities,
    setActivities,
    speciality,
    setSpeciality,
    specialities,
    setSpecialities,
    administrativeType,
    setAdministrativeType,
    administrativeTypes,
    setAdministrativeTypes,
    certification,
    setCertification,
    certifications,
    setCertifications,
    specialistType,
    setSpecialistType,
    specialistTypes,
    setSpecialistTypes,
    officeSpecialistType,
    setOfficeSpecialistType,
    officeSpecialistSpecialities,
    setOfficeSpecialistSpecialities,
    officeSpecialistTarget,
    setOfficeSpecialistTarget,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setState,
    state,
    newCoordonnate,
    setNewCoordonnate,
}) {
    const [displayTable, setDisplayTable] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [firstNameOut, setFirstNameOut] = useState('')
    const [csvName, setCsvName] = useState('')
    const [csvSpecialistType, setCsvSpecialistType] = useState([])
    const [openCsvPopup, setOpenCsvPopup] = useState(false)
    const [openExportCvPopup, setOpenExportCvPopup] = useState(false)
    const [openExportDocumentPopup, setOpenExportDocumentPopup] = useState(false)
    const [active, setActive] = useState({
        prosconsulte: true,
        prestataire: false,
        geopsy: false,
        officeSpecialist: false,
    })
    const [allSpecialists, setAllSpecialists] = useState([])
    const [allOfficeSpecialists, setAllOfficeSpecialists] = useState([])
    const [exportSpecialistList, setExportSpecialistList] = useState([])
    const [noSpecialist, setNoSpecialist] = useState(false)
    const [noOfficeSpecialist, setNoOfficeSpecialist] = useState(false)
    const [isFiltered, setIsFiltered] = useState(false)
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [specialist, setSpecialist] = useState([])
    const [cvSpeciality, setCvSpeciality] = useState('')
    const [documentTypes, setDocumentTypes] = useState('')
    const [documentType, setDocumentType] = useState('')
    const [cvs, setCvs] = useState(false)
    const [error, setError] = useState({})
    const [selectedCv, setSelectedCv] = useState('')
    const [openSharePopup, setOpenSharePopup] = useState(false)
    const [spinner, setSpinner] = useState(false)
    let navigate = useNavigate()
    document.title = 'Codex - Liste des psychologues'
    
    const {
        view,
        totalItems,
        specialists,
        fetchSpecialists,
        deleteSpecialist
    } = useSpecialists()

    const {
        officeSpecialistView,
        fetchOfficeSpecialists,
        deleteOfficeSpecialist
    } = useOfficeSpecialists()

    useEffect(() => {
        (async () => {
            if (filterList &&  filterList[4]) {
                setAdministrativeType([])
                const tab = []
                filterList.map((list) => {
                    if (filterList[4] === list) {
                        tab.push(false)
                    } else {
                        tab.push(list)
                    }
                    return null
                })
                setFilterList(tab)
            }
            if (!filterList) {
                const specialistsFetch = await fetchSpecialists(`/specialists?administrativeTypes.name[]=Pros-Consulte&`)
                setIsFiltered(true)
                setAllSpecialists(specialistsFetch)
            }
            const fetchExportSpecialistList = await apiFetch(`/specialists-export-document-list?administrativeTypes.name[]=Pros-Consulte&pagination=false`)
            if (fetchExportSpecialistList && fetchExportSpecialistList['hydra:member']) {
                setExportSpecialistList(fetchExportSpecialistList['hydra:member'])
            }
            setIsReady(true)
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDelete = async (specialist) => {
        handleToggle()
        const deletedIntervention = await deleteSpecialist(specialist.uuid)

        if (deletedIntervention && deletedIntervention.ok) {
            setText('Le spécialiste a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deletedIntervention && deletedIntervention['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        handleCloseBackdrop()
    }

    const handleOfficeSpecialistDelete = async (officeSpecialist) => {
        handleToggle()
        const deletedOfficeSpecialist = await deleteOfficeSpecialist(officeSpecialist.uuid)

        if (deletedOfficeSpecialist && deletedOfficeSpecialist.ok) {
            setText('Le psychologue en cabinet a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deletedOfficeSpecialist && deletedOfficeSpecialist['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        handleCloseBackdrop()
    }

    const handleAddSpecialist = () => {
        navigate(`/specialists/add`)
    }

    const handleFavorite = async (uuid) => {
        setIsFiltered(false)
        setAllSpecialists([])
        const manageFavorite = await apiFetch(`/specialists/${uuid}/favorite`)
        if (manageFavorite && manageFavorite.success) {
            setText(manageFavorite.message)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            if (!filterList) {
                const refreshSpecialist = await fetchSpecialists(`/specialists?administrativeTypes.name[]=${foundAdministrativeType()}&`)
                setAllSpecialists(refreshSpecialist)
                setIsFiltered(true)
            }
        } else {
            setText('Erreur le spécialist n\'a pas pu être ajouté au favoris')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const enterHandler = async () => {
        setSpinner(true)
        if (officeSpecialistView) {
            const officeSpecialists = await fetchOfficeSpecialists(officeSpecialistView['hydra:next'])
            if (officeSpecialists) {
                setAllOfficeSpecialists((officeSpecialists && allOfficeSpecialists.concat(officeSpecialists)))
            }
        } else if (view) {
            const specialists = await fetchSpecialists(view['hydra:next'])
            if (specialists) {
                setAllSpecialists((specialists && allSpecialists.concat(specialists)))
            }
        }
        setSpinner(false)
    };

    const handleDisplayTable = async () => {
        if (!filterList && !isFiltered) {
            await fetchSpecialists('/specialists')
        }
        setDisplayTable(!displayTable)
    }

    const handleSharePopup = async (specialistId) => {
        handleToggle()
        if (!openSharePopup) {
            const fetchCvs = await apiFetch(`/specialists?cvs?specialist.uuid=${specialistId}`)
            if (fetchCvs && fetchCvs['hydra:member']) {
                setCvs(fetchCvs['hydra:member'])
            } else {
                setText('Impossible de partager le cv de ce spécialiste')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            setOpenSharePopup(specialistId)
        } else {
            setOpenSharePopup(false)
        }
        handleCloseBackdrop()
    }

    const handleShare = async () => {
        setError({})
        const cvBody = selectedCv ? {
            specialist: `specialists/${openSharePopup}`,
            content: message,
            email: email,
            cv: selectedCv
        } : {
            specialist: `specialists/${openSharePopup}`,
            content: message,
            email: email,
        }

        const shareCv = await apiFetch('/sent_cvs', {
            method: 'POST',
            body: JSON.stringify(cvBody)
        })
        if (shareCv && shareCv['@id']) {
            setText('Le CV a bien été envoyé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenSharePopup(false)
        } else {
            const errorField = handleFormError(shareCv, ['email', 'message'])
            setError(errorField)
            setText('Impossible de partager le cv de ce spécialiste')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleChangeTab = async (tab) => {
        setFirstNameOut('')
        setIsFiltered(false)
        setAllSpecialists([])
        setAllOfficeSpecialists([])
        setSpinner(true);
        const getFilter = handleFilter(speciality, firstName, lastName, language, administrativeType, specialistType, certification, state)
        const getOfficeSpecialistFilter = handleOfficeSpecialistFilter(officeSpecialistType, officeSpecialistSpecialities, officeSpecialistTarget, firstName, lastName)

        switch(tab) {
            case 'prosconsulte':
                setActive({prosconsulte: true, prestataire: false, geopsy: false, officeSpecialist: false})
                const ProsConcsulteFetch =
                    await fetchSpecialists(
                        removeLastAmpersand(`/specialists?${`administrativeTypes.name[]=Pros-Consulte&`}${getFilter.officeFilter}${getFilter.homeInterventionFilter}${getFilter.appFilter}${getFilter.auditFilter}${getFilter.coachingFilter}${getFilter.conflictResolutionSupportFilter}${getFilter.crisisUnitFilter}${getFilter.harassmentInvestigationFilter}${getFilter.certifiedCoachingFilter}${getFilter.platformFilter}${getFilter.projectManagerFilter}${getFilter.pseFilter}${getFilter.gpAudioFilter}${getFilter.gpVisioFilter}${getFilter.foundFirstName}${getFilter.languagesFilter}${getFilter.administrativeTypeFilter}${getFilter.specialistTypeFilter}${getFilter.certificationFilter}${getFilter.foundLastName}${getFilter.chosenSpecialities}`)
                    )
                setAllSpecialists(ProsConcsulteFetch)
                setIsFiltered(true)
                setSpinner(false);
                return
            case 'prestataire':
                setActive({prosconsulte: false, prestataire: true, geopsy: false, officeSpecialist: false})
                const PrestataireFetch =
                    await fetchSpecialists(
                        removeLastAmpersand(`/specialists?${`administrativeTypes.name[]=Prestataire&`}${getFilter.officeFilter}${getFilter.homeInterventionFilter}${getFilter.appFilter}${getFilter.auditFilter}${getFilter.coachingFilter}${getFilter.conflictResolutionSupportFilter}${getFilter.crisisUnitFilter}${getFilter.harassmentInvestigationFilter}${getFilter.certifiedCoachingFilter}${getFilter.platformFilter}${getFilter.projectManagerFilter}${getFilter.pseFilter}${getFilter.gpAudioFilter}${getFilter.gpVisioFilter}${getFilter.foundFirstName}${getFilter.languagesFilter}${getFilter.administrativeTypeFilter}${getFilter.specialistTypeFilter}${getFilter.certificationFilter}${getFilter.foundLastName}${getFilter.chosenSpecialities}`)
                    )
                setAllSpecialists(PrestataireFetch)
                setIsFiltered(true)
                setSpinner(false);
                return
            case 'geopsy':
                setActive({prosconsulte: false, prestataire: false, geopsy: true, officeSpecialist: false})
                const GeoPsyFetch =
                    await fetchSpecialists(
                        removeLastAmpersand(`/specialists?${`administrativeTypes.name[]=Geo-Psy&`}${getFilter.officeFilter}${getFilter.homeInterventionFilter}${getFilter.appFilter}${getFilter.auditFilter}${getFilter.coachingFilter}${getFilter.conflictResolutionSupportFilter}${getFilter.crisisUnitFilter}${getFilter.harassmentInvestigationFilter}${getFilter.certifiedCoachingFilter}${getFilter.platformFilter}${getFilter.projectManagerFilter}${getFilter.pseFilter}${getFilter.gpAudioFilter}${getFilter.gpVisioFilter}${getFilter.foundFirstName}${getFilter.languagesFilter}${getFilter.administrativeTypeFilter}${getFilter.specialistTypeFilter}${getFilter.certificationFilter}${getFilter.foundLastName}${getFilter.chosenSpecialities}`)
                    )
                setAllSpecialists(GeoPsyFetch)
                setIsFiltered(true)
                setSpinner(false);
                return
            default:
                setActive({prosconsulte: false, prestataire: false, geopsy: false, officeSpecialist: true})
                const officeSpecialistFetch =
                    await fetchOfficeSpecialists(
                        removeLastAmpersand(`/office_specialists?${getOfficeSpecialistFilter.officeSpecialistTypeFilter}${getOfficeSpecialistFilter.officeSpecialistSpecialitiesFilter}${getOfficeSpecialistFilter.officeSpecialistTargetFilter}${getOfficeSpecialistFilter.foundFirstName}${getOfficeSpecialistFilter.foundLastName}`)
                    )
                setAllOfficeSpecialists(officeSpecialistFetch)
                setAllSpecialists(null)
                setIsFiltered(true)
                setSpinner(false);
                return
        }
    }

    const foundAdministrativeType = () => {
        if (active.prosconsulte) {
            return 'Pros-Consulte'
        } else if (active.prestataire) {
            return 'Prestataire'
        }
        return 'Geo-Psy'
    }

    const handleSearch = async (e) => {
        clearTimeout(debounce)
        setFirstNameOut(e.target.value)
        if (e.target.value.length > 1) {
            debounce = setTimeout(async () => {
                setLoading(true)
                if (active.officeSpecialist) {
                    const officeSpecialistsFetch = await fetchOfficeSpecialists(`/office_specialists?fullName=${e.target.value}`)
                    setAllOfficeSpecialists(officeSpecialistsFetch)
                } else {
                    const specialistsFetch = await fetchSpecialists(`/specialists?fullName=${e.target.value}`)
                    setAllSpecialists(specialistsFetch)
                }
                setIsFiltered(true)
                setLoading(false)
            }, 500)
        }
        if (e.target.value.length === 0) {
            setLoading(true)
            if (active.officeSpecialist) {
                const getFilter = handleOfficeSpecialistFilter(officeSpecialistType, officeSpecialistSpecialities, officeSpecialistTarget, firstName, lastName)
                const officeSpecialistsFetch = await fetchOfficeSpecialists(removeLastAmpersand(`/office_specialists?${getFilter.officeSpecialistTypeFilter}${getFilter.officeSpecialistSpecialitiesFilter}${getFilter.officeSpecialistTargetFilter}${getFilter.foundFirstName}${getFilter.foundLastName}`))
                setAllOfficeSpecialists(officeSpecialistsFetch)
            } else {
                const getFilter = handleFilter(speciality, firstName, lastName, language, administrativeType, specialistType, certification, state)
                const specialistsFetch = await fetchSpecialists(removeLastAmpersand(`/specialists?${`administrativeTypes.name[]=${foundAdministrativeType()}&`}${getFilter.officeFilter}${getFilter.homeInterventionFilter}${getFilter.appFilter}${getFilter.auditFilter}${getFilter.coachingFilter}${getFilter.conflictResolutionSupportFilter}${getFilter.crisisUnitFilter}${getFilter.harassmentInvestigationFilter}${getFilter.certifiedCoachingFilter}${getFilter.platformFilter}${getFilter.projectManagerFilter}${getFilter.pseFilter}${getFilter.gpAudioFilter}${getFilter.gpVisioFilter}${getFilter.foundFirstName}${getFilter.languagesFilter}${getFilter.administrativeTypeFilter}${getFilter.specialistTypeFilter}${getFilter.certificationFilter}${getFilter.foundLastName}${getFilter.chosenSpecialities}`))
                setAllSpecialists(specialistsFetch)
            }
            setLoading(false)
        }
    }

    const handleExportSpecialist = async () => {
        let exportCsv = {}
        if (csvName) {
            exportCsv = await apiFetch(`/document/csv/${csvName}`, {}, false, false, true)
        } else if (csvSpecialistType && csvSpecialistType.length) {
            let url = ''
            csvSpecialistType.map(type => url += `,${type}`)
            const finalUrl = url.substring(1)
            exportCsv = await apiFetch(`/document/csv/${finalUrl}`, {}, false, false, true)
        }

        if (exportCsv.ok) {
            const blob = await exportCsv.blob()
            download(blob, `ListeDesSpécialistes-${csvName}`)
            setCsvName('')
            setCsvSpecialistType([])
            setOpenCsvPopup(false)
        }
    }

    const handleExportSpecialistFilter = async () => {
        setLoading(true)

        const city = document.getElementById('city').value;

        const cityFilter = city ? `city=${city}&` : '';
        const languagesFilter = language ? formatMultipleOptions('languageNames', language) : ''
        const specialityFilter = speciality ? formatMultipleOptions('specialityNames', speciality) : ''
        const specialistTypeFilter = specialistType ? formatMultipleOptions('specialistTypeNames', specialistType) : ''
        const certificationFilter = certification ? formatMultipleOptions('certificationNames', certification) : ''
        const homeInterventionFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'homeIntervention') ? `activityHomeIntervention=true&` : ''
        const appFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'app') ? `activityApp=true&` : ''
        const auditFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'audit') ? `activityAudit=true&` : ''
        const coachingFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'coaching') ? `activityCoaching=true&` : ''
        const conflictResolutionSupportFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'conflictResolutionSupport') ? `activityConflictResolutionSupport=true&` : ''
        const crisisUnitFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'crisisUnit') ? `activityCrisisUnit=true&` : ''
        const harassmentInvestigationFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'harassmentInvestigation') ? `activityHarassmentInvestigation=true&` : ''
        const certifiedCoachingFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'certifiedCoaching') ? `activityCertifiedCoaching=true&` : ''
        const platformFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'platform') ? `activityPlatform=true&` : ''
        const projectManagerFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'projectManager') ? `activityProjectManager=true&` : ''
        const rpsCoachFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'rpsCoach') ? `activityRpsCoach=true&` : ''
        const pseFilter = activity && activity.length &&
        !!activity.find((item) => item.id === 'pse') ? `activityPse=true&` : ''
        const officeFilter = state.office ? `exists[offices]&` : ''
        const iprpFilter = state.iprp ? `iprp=true&` : ''
        const pssmFilter = state.pssm ? `pssm=true&` : ''
        const gpVisioFilter = state.gpVisio ? `activityVisio=${state.gpVisio}&` : ''
        const gpAudioFilter = state.gpAudio ? `activityCall=${state.gpAudio}&` : ''
        const exportSpecialist = await apiFetch(removeLastAmpersand(`/specialists/export?${foundAdministrativeType ? `administrativeTypeNames[]=${foundAdministrativeType()}&` : ''}${cityFilter}${languagesFilter}${specialityFilter}${specialistTypeFilter}${certificationFilter}${homeInterventionFilter}${appFilter}${auditFilter}${coachingFilter}${conflictResolutionSupportFilter}${crisisUnitFilter}${harassmentInvestigationFilter}${certifiedCoachingFilter}${platformFilter}${projectManagerFilter}${rpsCoachFilter}${pseFilter}${officeFilter}${iprpFilter}${pssmFilter}${gpVisioFilter}${gpAudioFilter}pagination=false`), {}, false, false, true)

        if (exportSpecialist.ok) {
            const blob = await exportSpecialist.blob()
            download(blob, 'Liste des spécialistes')
            setCsvName('')
            setCsvSpecialistType([])
            setOpenCsvPopup(false)
        }
        setLoading(false)
    }

    const handleExportSpecialistCv = async () => {
        setLoading(true)
        let exportCsv = {}
        if (specialist && specialist.length) {
            exportCsv = await apiFetch(`/specialists/cv?${formatName()}`, {}, false, false, true)
        } else if (cvSpeciality && cvSpeciality.length) {
            if (administrativeType && administrativeType.length) {
                exportCsv = await apiFetch(`/specialists/cv?${formatSpecialities()}&${formatAdministrativeTypes()}`, {}, false, false, true)
            } else {
                exportCsv = await apiFetch(`/specialists/cv?${formatSpecialities()}`, {}, false, false, true)
            }
        } else if (administrativeType && administrativeType.length) {
            exportCsv = await apiFetch(`/specialists/cv?${formatAdministrativeTypes()}`, {}, false, false, true)
        }

        if (exportCsv.ok) {
            const blob = await exportCsv.blob()
            download(blob, `Cvs des psys`)
            setCsvName('')
            setCsvSpecialistType([])
            setOpenCsvPopup(false)
        }
        setLoading(false)
    }

    const handleExportSpecialistDocument = async () => {
        setLoading(true)
        setError({})
        let exportCsv = {}
        if (!specialist) {
            setLoading(false)
            return setError({specialist:"Vous devez sélectionner au moins un spécialiste."})
        }
        if (documentType && documentType.length > 0) {
            exportCsv = await apiFetch(`/specialists/document?${formatName()}&${formatDocumentType()}`, {}, false, false, true)
        } else {
            exportCsv = await apiFetch(`/specialists/document?${formatName()}`, {}, false, false, true)
        }

        if (exportCsv.ok) {
            const blob = await exportCsv.blob()
            download(blob, `Documents des psys`)
            setCsvName('')
            setCsvSpecialistType([])
            setOpenCsvPopup(false)
        }
        setLoading(false)
    }

    const formatName = () => {
        let string = ''
        specialist.map((item) => {
            return string += `firstName[]=${item.firstName}&`
        })
        return string
    }

    const formatSpecialities = () => {
        let string = ''
        cvSpeciality.map((item) => {
            return string += `specialities[]=${item.name}&`
        })
        return string
    }

    const formatDocumentType = () => {
        let string = ''
        documentType.map((item) => {
            return string += `documentType[]=${item.name}&`
        })
        return string
    }

    const formatAdministrativeTypes = () => {
        let string = ''
        administrativeType.map((item) => {
            return string += `administrativeTypes[]=${item.name}&`
        })
        return string
    }

    const handleExportAllCv = async () => {
        setLoading(true)
        const exportCvs = await apiFetch('/specialists/cv', {}, false, false, true)

        if (exportCvs.ok) {
            const blob = await exportCvs.blob()
            download(blob, `Tous les Cvs des psys`)
            setCsvName('')
            setCsvSpecialistType([])
            setOpenCsvPopup(false)
        }
        setLoading(false)
    }

    const handleOpenExportSpecialist = () => {
        setOpenCsvPopup(!openCsvPopup)
    }

    const handleOpenExportSpecialistCv = () => {
        setOpenExportCvPopup(!openCsvPopup)
    }

    const handleOpenExportSpecialistDocument = () => {
        setOpenExportDocumentPopup(!openCsvPopup)
    }
    
    return (
        <Zoom in={zoom}>
            <div>
                <Paper
                    style={phoneResponsive ? {} : {padding: 15, paddingLeft: 30, marginBottom: 30, marginTop: 0}}
                    sx={{
                        marginBottom: 30,
                        padding: '10px 20px',
                        display: 'flex',
                    }}
                >
                    <div style={{flex: '1 1 100%', marginTop: 10}}>
                        <Button
                            startIcon={<HomeIcon />}
                            onClick={() => handleChangeTab('prosconsulte')}
                            variant={active.prosconsulte ? 'contained' : 'text'}
                            color={darkState ? 'secondary' : 'primary'}
                            style={
                                active.prosconsulte ? {
                                    marginRight: phoneResponsive ? 0 : 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)',
                                    backgroundColor: '#EE8639'
                                } : {
                                    marginRight: phoneResponsive ? 0 : 20
                                }
                            }
                        >
                            Spécialistes Pros-Consulte
                        </Button>
                        <Button
                            startIcon={<MapIcon />}
                            onClick={() => handleChangeTab('geopsy')}
                            variant={active.geopsy ? 'contained' : 'text'}
                            color={darkState ? 'secondary' : 'primary'}
                            style={
                                active.geopsy ? {
                                    marginRight: phoneResponsive ? 0 : 20,
                                    boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)',
                                    backgroundColor: '#f44336'
                                } : {
                                    marginRight: phoneResponsive ? 0 : 20
                                }
                            }
                        >
                            Spécialistes GeoPsy
                        </Button>
                        <Button
                            startIcon={<HomeWorkIcon />}
                            onClick={() => handleChangeTab('prestataire')}
                            variant={active.prestataire ? 'contained' : 'text'}
                            color={darkState ? 'secondary' : 'primary'}
                            style={
                                active.prestataire ? {
                                    marginRight: phoneResponsive ? 0 : 20,
                                    boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)',
                                    backgroundColor: '#ba000d'
                                } : {
                                    marginRight: phoneResponsive ? 0 : 20
                                }
                            }
                        >
                            Spécialistes Prestataire
                        </Button>
                        <Button
                            startIcon={<EmojiTransportationIcon />}
                            onClick={() => handleChangeTab('office_specialists')}
                            variant={active.officeSpecialist ? 'contained' : 'text'}
                            color={darkState ? 'secondary' : 'primary'}
                            style={
                                active.officeSpecialist ? {
                                    marginRight: phoneResponsive ? 0 : 20,
                                    boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)',
                                    backgroundColor: '#27da0d'
                                } : {
                                    marginRight: phoneResponsive ? 0 : 20
                                }
                            }
                        >
                            Psychologues en cabinet
                        </Button>
                    </div>
                    <div>
                        <Tooltip TransitionComponent={Zoom} title={'minimum 3 lettres'}>
                            <TextField
                                style={phoneResponsive ? {width: 215} : {width: 300}}
                                color={darkState ? 'secondary' : 'primary'}
                                label='Nom/Prénom'
                                id='firstNameOut'
                                name='firstNameOut'
                                onChange={handleSearch}
                                value={firstNameOut}
                            />
                        </Tooltip>
                    </div>
                </Paper>
                {allSpecialists && allSpecialists.length > 0 ?
                <SpecialistFilters
                    newCoordonnate={newCoordonnate}
                    setNewCoordonnate={setNewCoordonnate}
                    isFromList={true}
                    setIsFiltered={setIsFiltered}
                    activities={activities}
                    setActivities={setActivities}
                    activity={activity}
                    setActivity={setActivity}
                    foundAdministrativeType={foundAdministrativeType}
                    setAllSpecialists={setAllSpecialists}
                    handleDisplayTable={handleDisplayTable}
                    handleOpenExportSpecialist={handleOpenExportSpecialist}
                    handleExportSpecialistFilter={handleExportSpecialistFilter}
                    handleOpenExportSpecialistCv={handleOpenExportSpecialistCv}
                    handleOpenExportSpecialistDocument={handleOpenExportSpecialistDocument}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    setNoSpecialist={setNoSpecialist}
                    state={state}
                    setState={setState}
                    language={language}
                    setLanguage={setLanguage}
                    languages={languages}
                    setLanguages={setLanguages}
                    speciality={speciality}
                    setSpeciality={setSpeciality}
                    specialities={specialities}
                    setSpecialities={setSpecialities}
                    administrativeType={administrativeType}
                    setAdministrativeType={setAdministrativeType}
                    administrativeTypes={administrativeTypes}
                    setAdministrativeTypes={setAdministrativeTypes}
                    certification={certification}
                    setCertification={setCertification}
                    certifications={certifications}
                    setCertifications={setCertifications}
                    specialistType={specialistType}
                    setSpecialistType={setSpecialistType}
                    specialistTypes={specialistTypes}
                    setSpecialistTypes={setSpecialistTypes}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    phoneResponsive={phoneResponsive}
                    fetchSpecialists={fetchSpecialists}
                    zoom={zoom}
                    darkState={darkState}
                    isReady={isReady}
                    setDisplayTable={setDisplayTable}
                    handleAddSpecialist={handleAddSpecialist}
                    view={view}
                    displayTable={displayTable}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                /> :
                <OfficeSpecialistFilters
                    newCoordonnate={newCoordonnate}
                    setNewCoordonnate={setNewCoordonnate}
                    isFromList={true}
                    setIsFiltered={setIsFiltered}
                    setAllOfficeSpecialists={setAllOfficeSpecialists}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    setNoOfficeSpecialist={setNoOfficeSpecialist}
                    state={state}
                    setState={setState}
                    officeSpecialistType={officeSpecialistType}
                    setOfficeSpecialistType={setOfficeSpecialistType}
                    officeSpecialistSpecialities={officeSpecialistSpecialities}
                    setOfficeSpecialistSpecialities={setOfficeSpecialistSpecialities}
                    officeSpecialistTarget={officeSpecialistTarget}
                    setOfficeSpecialistTarget={setOfficeSpecialistTarget}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    phoneResponsive={phoneResponsive}
                    fetchOfficeSpecialists={fetchOfficeSpecialists}
                    zoom={zoom}
                    darkState={darkState}
                    officeSpecialistView={officeSpecialistView}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                    />
                }

                {allSpecialists && allSpecialists.length > 0 ?
                    <Grid
                        container
                        spacing={ specialists && specialists.length ? 8 : 0}
                        style={
                            specialists && specialists.length
                                ? phoneResponsive
                                    ? { maxWidth: "320px" }
                                    : !isFiltered
                                        ? { marginLeft: 1 }
                                        : {}
                                : { marginTop: -20, marginLeft: 0 }
                        }
                    >
                    {!loading && !displayTable && specialists && specialists.length && isFiltered
                        ? allSpecialists && allSpecialists.length && allSpecialists.map((specialist, index) =>
                            <Grid key={index} item xs={12} sm={3}>
                                {index === allSpecialists.length - 1 && <Waypoint onEnter={() => enterHandler(index)} />}
                                <UserCard
                                    setAllSpecialists={setAllSpecialists}
                                    fetchSpecialists={fetchSpecialists}
                                    handleSharePopup={handleSharePopup}
                                    handleFavorite={handleFavorite}
                                    handleDelete={handleDelete}
                                    darkState={darkState}
                                    specialist={specialist}
                                    setText={setText}
                                    setTransition={setTransition}
                                    setOpenSnackbar={setOpenSnackbar}
                                    setSeverity={setSeverity}
                                />
                            </Grid>)
                        : !loading && !displayTable && !noSpecialist && !isFiltered
                            ? <LinearProgress />
                            : !spinner && !loading && !displayTable &&
                                <Typography style={{marginTop: 100, marginLeft: 100}}>
                                    Pas de spécialiste disponible avec ces filtres
                                </Typography>
                    }
                    </Grid>
                :
                    <Grid
                        container
                        spacing={ allOfficeSpecialists && allOfficeSpecialists.length ? 8 : 0}
                        style={
                            allOfficeSpecialists && allOfficeSpecialists.length
                                ? phoneResponsive
                                    ? { maxWidth: "320px" }
                                    : !isFiltered
                                        ? { marginLeft: 1 }
                                        : {}
                                : { marginTop: -20, marginLeft: 0 }
                        }
                    >
                    {!loading && allOfficeSpecialists && allOfficeSpecialists.length ?
                        allOfficeSpecialists.map((officeSpecialist, index) =>
                            <Grid key={index} item xs={12} sm={3}>
                                {index === allOfficeSpecialists.length - 1 && <Waypoint onEnter={() => enterHandler(index)} />}
                                <OfficeSpecialistCard
                                    setAllOfficeSpecialists={setAllOfficeSpecialists}
                                    fetchOfficeSpecialists={fetchOfficeSpecialists}
                                    handleOfficeSpecialistDelete={handleOfficeSpecialistDelete}
                                    darkState={darkState}
                                    officeSpecialist={officeSpecialist}
                                    setText={setText}
                                    setTransition={setTransition}
                                    setOpenSnackbar={setOpenSnackbar}
                                    setSeverity={setSeverity}
                                />
                            </Grid>)
                        : !spinner && !loading && !noOfficeSpecialist && !isFiltered &&
                            <Typography style={{marginTop: 100, marginLeft: 100}}>
                                Pas de psychologue en cabinet disponible avec ces filtres
                            </Typography>
                    }
                    </Grid>
                }

                <ShareCvPopup
                    error={error}
                    selectedCv={selectedCv}
                    setSelectedCv={setSelectedCv}
                    cvs={cvs}
                    setEmail={setEmail}
                    setMessage={setMessage}
                    darkState={darkState}
                    handleAccept={handleShare}
                    handleClose={handleSharePopup}
                    open={openSharePopup}
                />
                <CsvPopup
                    setCsvSpecialistType={setCsvSpecialistType}
                    csvSpecialistType={csvSpecialistType}
                    csvName={csvName}
                    setCsvName={setCsvName}
                    darkState={darkState}
                    handleAccept={handleExportSpecialist}
                    handleClose={() => setOpenCsvPopup(!openCsvPopup)}
                    open={openCsvPopup}
                />
                {specialists && <ExportCvPopup
                    setAdministrativeType={setAdministrativeType}
                    administrativeTypes={administrativeTypes}
                    loading={loading}
                    handleExportAllCv={handleExportAllCv}
                    setCvSpeciality={setCvSpeciality}
                    cvSpeciality={cvSpeciality}
                    setSpecialist={setSpecialist}
                    specialist={specialist}
                    specialists={specialists}
                    specialities={specialities}
                    darkState={darkState}
                    handleAccept={handleExportSpecialistCv}
                    handleClose={() => setOpenExportCvPopup(!openExportCvPopup)}
                    open={openExportCvPopup}
                />}
                {specialists && <ExportDocumentPopup
                    loading={loading}
                    setDocumentTypes={setDocumentTypes}
                    documentTypes={documentTypes}
                    setDocumentType={setDocumentType}
                    documentType={documentType}
                    setSpecialist={setSpecialist}
                    specialist={specialist}
                    specialists={exportSpecialistList}
                    darkState={darkState}
                    handleAccept={handleExportSpecialistDocument}
                    handleClose={() => setOpenExportDocumentPopup(!openExportDocumentPopup)}
                    open={openExportDocumentPopup}
                    error={error}
                />}
                {specialists && displayTable && <EnhancedTable
                    totalItems={totalItems}
                    view={view}
                    fetchSpecialists={fetchSpecialists}
                    phoneResponsive={phoneResponsive}
                    handleDelete={handleDelete}
                    darkState={darkState}
                    rows={specialists}
                />}
                {(spinner || (loading && firstNameOut.length > 2)) &&
                    <div style={{textAlign: 'center', marginTop: 40}}>
                        <CircularProgress color={darkState ? 'secondary' : 'primary'}/>
                    </div>}
            </div>
        </Zoom>
    )
}
